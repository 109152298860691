/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useCallback, useEffect, useState, useRef } from 'react';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Box } from 'rebass';
import { useCraftTranslations } from '~/utils/hooks/useCraftGlobals';
import CalculatorLayout from '~/components/calculator/CalculatorLayout';
import CalculatorPricingBox from '~/components/calculator/CalculatorPricingBox';
import CalculatorStepSection from '../CalculatorStepSection';
import { useCraftCalculator } from '~/utils/hooks/useCraftCalculator';
import { useSearchParam } from '~/utils/hooks/useSearchParam';
import ButtonGroup, { ButtonGroupItem } from '~/components/common/ButtonGroup';
import Button from '~/components/common/Button';
import ThemeProvider from '~/components/common/ThemeProvider';
import { useCraftMaterials } from '~/utils/hooks/useCraftMaterials';
import Loadspinner from '~/components/common/Loadspinner';
import { contractTable } from './contractTable';
import Table from '~/components/common/Table';
import Parser from '~/components/common/Parser';
import { reportConversionContractConclusionButton } from '~/utils/tracking';
import { TermsLabel, TermsInput } from './CalculatorConcludeContract.styles';
import { useSetStepIndex } from '~/components/common/Calculator/useSetStepIndex';
import { useCraftGlobals } from '~/utils/hooks/useCraftGlobals';
import IntCampaign from '~/components/common/IntCampaign';
import { ThemeName } from '~/components/common/ThemeProvider/ThemeProviderImplementation';
import slugify from '~/utils/slugify';

type CalculatorConcludeContractProps = {
  className?: string;
  conclusionContent?: React.ReactNode;
  conclusionTermsContent?: React.ReactNode;
};

const trustedShopsPayment = {
  Debit: 'DIRECT_DEBIT',
  Invoice: 'INVOICE',
  PayPal: 'PAYPAL',
  CreditCard: 'CREDIT_CARD',
};

export default function CalculatorConcludeContract({
  className,
  conclusionContent,
  conclusionTermsContent,
}: CalculatorConcludeContractProps) {
  const t = useCraftTranslations();
  const calculator = useCraftCalculator();

  const { materialMap } = useCraftMaterials();

  const contractId = useSearchParam('id');

  const redirectError = useSearchParam('error');

  const setCalculatorStepIndex = useSetStepIndex();

  useEffect(() => {
    // this auto-navigates back to the calculator, keeping the error
    if (redirectError) setCalculatorStepIndex(undefined, true);
  }, [redirectError]);

  const [hasAgreedToTerms, setAgreedToTerms] = useState(false);

  useEffect(() => {
    // if there is explicitly no contractId
    if (contractId === null) {
      location.pathname = calculator.fullUri;
    }
  });

  const now = useRef<Date>();

  useEffect(() => {
    now.current = new Date();
  }, []);

  const contractQuery = gql`
    query Contract($contractId: String!) {
      contract(contractId: $contractId) {
        contract {
          companyData {
            companyName
            agentNumber
            title
            salutation
            firstName
            lastName
            address1
            address2
            city
            country
            zipCode
            email
            telephone
            fax
          }
          taxData {
            EUVATNumber
            DETaxNumber
            commerceNumber
          }
          license {
            registrationNumber
            temporaryRegistrationNumber
          }
          amounts {
            materialId
            amount
          }
        }
        price
        startYear
        paymentMethod
      }
    }
  `;

  const {
    loading: contractLoading,
    error: contractError,
    data: contractData,
    refetch: refetchContract,
  } = useQuery(contractQuery, {
    variables: { contractId },
    skip: !contractId,
  });

  const submitContractMutation = gql`
    mutation submitContract($contractId: String!, $origin: String) {
      submitContract(input: { contractId: $contractId, origin: $origin }) {
        error
      }
    }
  `;

  const [
    networkSubmitContractError,
    setNetworkSubmitContractError,
  ] = useState();

  const [
    submitContract,
    {
      loading: submitContractLoading,
      data: submitContractData,
      error: _submitContractError,
    },
  ] = useMutation(submitContractMutation);

  const { globalSiteSettings } = useCraftGlobals();

  const handleSubmitContract = useCallback(
    function handleSubmitContract() {
      if (contractId) {
        reportConversionContractConclusionButton(
          undefined,
          contractData.contract.price,
        );

        (async () => {
          try {
            setNetworkSubmitContractError(undefined);
            await submitContract({
              variables: {
                contractId,
                origin: globalSiteSettings?.contractOrigin ?? undefined,
              },
            });
          } catch (e) {
            setNetworkSubmitContractError(e?.message);
          } finally {
            // formik.setSubmitting(false);
          }
        })();
      }
    },
    [contractData],
  );

  const [showRegistrationNumberWarning, setShowRegistrationNumberWarning] = useState(false);
  useEffect(() => {
    if (
      !!contractData &&
      !contractData?.contract?.contract?.license?.registrationNumber
    ) {
      setShowRegistrationNumberWarning(true);
    } else {
      setShowRegistrationNumberWarning(false);
    }
  }, [contractData])

  const submitContractError =
    _submitContractError ?? networkSubmitContractError;

  // Check contract submission to jump to top.
  const contractSubmitted =
    submitContractData && submitContractData.submitContract;

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.scrollTo({ top: 0 });
      (window as any)?.trustbadge?.reInitialize?.();
    }
  }, [contractSubmitted]);

  const pseudoTransactionId = slugify(
    [
      contractData?.contract?.contract?.companyData?.companyName,
      contractData?.contract?.startYear,
      (contractId ?? '').split('-')[0],
    ]
      .filter(Boolean)
      .join('-'),
  );

  if (contractSubmitted || contractId === 'test') {
    return (
      <Box className={className}>
        <CalculatorLayout
          themeName="secondary"
          main={
            <CalculatorStepSection
              headline={t('Licensing')}
              subHeadline={t('Contract concluded')}
            >
              {
                (
                  showRegistrationNumberWarning
                ) ? <Box
                    color="tertiaryText"
                    bg="tertiary"
                    my={1}
                    px={2}
                    py={'25px'}
                    css={{ borderRadius: 5 }}
                  >
                    {t(
                      'Please send us your registration number as soon as possible, so we can fully complete your order.',
                    )}
                  </Box>
                : null
              }
              <div id="trustedShopsCheckout" style={{ display: 'none', padding: '0 !important' }}>
                <span id="tsCheckoutOrderNr" style={{ display: 'none' }}>
                  {pseudoTransactionId}
                </span>
                <span id="tsCheckoutBuyerEmail" style={{ display: 'none' }}>
                  {contractData?.contract?.contract?.companyData?.email}
                </span>
                <span id="tsCheckoutOrderAmount" style={{ display: 'none' }}>
                  {contractData?.contract?.price}
                </span>
                <span id="tsCheckoutOrderCurrency" style={{ display: 'none' }}>
                  EUR
                </span>
                <span
                  id="tsCheckoutOrderPaymentType"
                  style={{ display: 'none' }}
                >
                  {
                    trustedShopsPayment?.[
                      contractData?.contract
                        ?.paymentMethod as keyof typeof trustedShopsPayment
                    ]
                  }
                </span>
                {now.current ? (
                  <span
                    id="tsCheckoutOrderEstDeliveryDate"
                    style={{ display: 'none' }}
                  >
                    {now.current.toISOString().split('T')[0]}
                  </span>
                ) : null}
              </div>
              <Box mt={2}>{conclusionContent}</Box>
              <ButtonGroup>
                <ButtonGroupItem>
                  <Button
                    width="100%"
                    type="button"
                    variant="filled"
                    textTransform="uppercase"
                    to="/"
                  >
                    {t('Back to home page')}
                  </Button>
                </ButtonGroupItem>
              </ButtonGroup>
            </CalculatorStepSection>
          }
          // aside={
          //   <IntCampaign
          //     position="static"
          //     hidePartner
          //     theme={ThemeName.primary}
          //     breakpoint="lg"
          //   />
          // }
          // asideMt={161}
        />
      </Box>
    );
  }

  return (
    <Box className={className}>
      <CalculatorLayout
        themeName="secondary"
        main={
          <CalculatorStepSection
            headline={t('Licensing')}
            subHeadline={t('Contract conclusion')}
          >
            {contractData &&
            contractData.contract &&
            contractData.contract.contract ? (
              <>
                <Box mb={1}>{t('Your license contains')}</Box>
                <Table>
                  <tbody>
                    {contractData.contract.contract.amounts.map(
                      ({
                        materialId,
                        amount,
                      }: {
                        materialId: number;
                        amount: number;
                      }) => {
                        if (amount <= 0) return null;
                        return (
                          <tr key={materialId}>
                            <td>
                              <strong>
                                <Parser
                                  html={materialMap[`${materialId}`].title}
                                />
                              </strong>
                            </td>
                            <td>
                              {amount} {t('kg')}
                            </td>
                          </tr>
                        );
                      },
                    )}
                  </tbody>
                </Table>

                <Box mt={2} mb={1}>
                  {t('Contract summary')}
                </Box>
                <Table appearance="alternate">
                  <tbody>
                    {contractTable.map(({ name, getValue }) => {
                      const value = getValue(contractData.contract);
                      if (!value) return null;
                      return (
                        <tr key={name}>
                          <td>
                            <strong>{t(name)}</strong>
                          </td>
                          <td>{value}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                <Box mt={2}>
                  <TermsLabel>
                    <TermsInput
                      type="checkbox"
                      onChange={() => setAgreedToTerms(!hasAgreedToTerms)}
                    ></TermsInput>
                    <span>{conclusionTermsContent}</span>
                  </TermsLabel>
                </Box>
              </>
            ) : (
              <>
                <Box fontSize={7} p={3} css={{ textAlign: 'center' }}>
                  {contractLoading ? (
                    <>
                      <Loadspinner loading />
                      <Box mt={2} fontSize={4}>
                        {t('Loading contract data…')}
                      </Box>
                    </>
                  ) : null}
                </Box>
                {contractError && contractError.message ? (
                  <Box color="errorText" mt={2}>
                    <strong>
                      {t('An error occured processing your data:')}
                    </strong>
                    <br />
                    <em>{t(contractError.message)}</em>
                    <Button onClick={() => refetchContract()}>
                      {t('Retry')}
                    </Button>
                  </Box>
                ) : null}
              </>
            )}

            {submitContractError && submitContractError.message ? (
              <Box color="errorText" mt={2}>
                <strong>{t('An error occured processing your data:')}</strong>
                <br />
                <em>{t(submitContractError?.graphQLErrors?.[0]?.message)}</em>
              </Box>
            ) : null}

            {submitContractLoading && (
              <Box fontSize={7} p={3} css={{ textAlign: 'center' }}>
                <Loadspinner loading />
                <Box mt={2} fontSize={4}>
                  {t(
                    'Your data is being processed, this might take up to one minute…',
                  )}
                </Box>
              </Box>
            )}

            {contractData ? (
              <ButtonGroup justifyContent="flex-end">
                <ButtonGroupItem width="65%">
                  <Button
                    width="100%"
                    type="button"
                    variant="filled"
                    textTransform="uppercase"
                    disabled={submitContractLoading || !hasAgreedToTerms}
                    onClick={handleSubmitContract}
                  >
                    {t('Conclude contract')}
                  </Button>
                </ButtonGroupItem>
              </ButtonGroup>
            ) : null}
          </CalculatorStepSection>
        }
        aside={
          contractData && contractData.contract ? (
            <ThemeProvider name="default">
              {/* @todo: put in non-fixed value */}
              <CalculatorPricingBox price={contractData.contract.price}>
                <Box fontSize={0}>
                  <strong>{t('paymentMethod.type')}</strong>
                  <br />
                  {t(contractData.contract.paymentMethod)}
                </Box>
              </CalculatorPricingBox>
            </ThemeProvider>
          ) : null
        }
        asideMt={161}
      />
    </Box>
  );
}
