import React from 'react';
import { graphql } from 'gatsby';
import CalculatorConcludeContract from '~/components/calculator/CalculatorConcludeContract';
import { Craft_CalculatorConcludeContract } from '~/query';
import Seo from '~/components/common/Seo';
import Parser, { ALLOWED_TAGS_BLOCK } from '~/components/common/Parser';

/**
 * Template: Calculator concluded contract
 *
 * Use this template in correspondence with a craft
 * section with the following properties:
 *
 * Type: single
 * Name: calculatorConcludeContract
 * URI: calculator/conclude-contract
 */
export default function TemplatePages({ data }: any) {
  const { title, seoTitle, seoDescription, seoCanonical } = (data?.craft?.entry ??
    {}) as Craft_CalculatorConcludeContract;

  // Text for conclusion
  const conclusionContentHtml =
    data?.craft?.entry?.calculatorContractConclusionText?.content ?? '';
  const conclusionContent = (
    <Parser html={conclusionContentHtml} allowedTags={ALLOWED_TAGS_BLOCK} />
  );

  // Text for conclusion terms
  const conclusionTermsContentHtml =
    data?.craft?.entry?.calculatorContractConclusionTermsText?.content ?? '';
  const conclusionTermsContent = <Parser html={conclusionTermsContentHtml} />;

  return (
    <>
      <Seo
        title={seoTitle ?? title}
        description={seoDescription ?? undefined}
        canonical={seoCanonical ?? undefined}
      />
      <CalculatorConcludeContract
        conclusionTermsContent={conclusionTermsContent}
        conclusionContent={conclusionContent}
      />
    </>
  );
}

export const query = graphql`
  query QueryCalculatorConcludeContractPage($id: [Int]!, $site: String!) {
    craft {
      entry(id: $id, site: $site) {
        id
        ... on Craft_CalculatorConcludeContract {
          calculatorContractConclusionText {
            content
          }
          calculatorContractConclusionTermsText {
            content
          }

          # SEO
          seoTitle
          seoDescription
        }
      }
    }
  }
`;
