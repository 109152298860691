/**
 * simplest way to get all the data enumerable
 * lives inside it's own file because of: long
 */
export const contractTable = [
  {
    name: 'startYear',
    getValue: (contractObject: any) => contractObject?.startYear,
  },
  {
    name: 'companyData.companyName',
    getValue: (contractObject: any) =>
      contractObject?.contract?.companyData?.companyName,
  },
  {
    name: 'companyData.agentNumber',
    getValue: (contractObject: any) =>
      contractObject?.contract?.companyData?.agentNumber,
  },
  {
    name: 'companyData.title',
    getValue: (contractObject: any) =>
      contractObject?.contract?.companyData?.title,
  },
  {
    name: 'companyData.salutation',
    getValue: (contractObject: any) =>
      contractObject?.contract?.companyData?.salutation,
  },
  {
    name: 'companyData.firstName',
    getValue: (contractObject: any) =>
      contractObject?.contract?.companyData?.firstName,
  },
  {
    name: 'companyData.lastName',
    getValue: (contractObject: any) =>
      contractObject?.contract?.companyData?.lastName,
  },
  {
    name: 'companyData.address1',
    getValue: (contractObject: any) =>
      contractObject?.contract?.companyData?.address1,
  },
  {
    name: 'companyData.address2',
    getValue: (contractObject: any) =>
      contractObject?.contract?.companyData?.address2,
  },
  {
    name: 'companyData.city',
    getValue: (contractObject: any) =>
      contractObject?.contract?.companyData?.city,
  },
  {
    name: 'companyData.country',
    getValue: (contractObject: any) =>
      contractObject?.contract?.companyData?.country,
  },
  {
    name: 'companyData.zipCode',
    getValue: (contractObject: any) =>
      contractObject?.contract?.companyData?.zipCode,
  },
  {
    name: 'companyData.email',
    getValue: (contractObject: any) =>
      contractObject?.contract?.companyData?.email,
  },
  {
    name: 'companyData.telephone',
    getValue: (contractObject: any) =>
      contractObject?.contract?.companyData?.telephone,
  },
  {
    name: 'companyData.fax',
    getValue: (contractObject: any) =>
      contractObject?.contract?.companyData?.fax,
  },
  {
    name: 'taxData.EUVATNumber',
    getValue: (contractObject: any) =>
      contractObject?.contract?.taxData?.EUVATNumber,
  },
  {
    name: 'taxData.DETaxNumber',
    getValue: (contractObject: any) =>
      contractObject?.contract?.taxData?.DETaxNumber,
  },
  {
    name: 'taxData.commerceNumber',
    getValue: (contractObject: any) =>
      contractObject?.contract?.taxData?.commerceNumber,
  },
  {
    name: 'license.registrationNumber',
    getValue: (contractObject: any) =>
      contractObject?.contract?.license?.registrationNumber,
  },
  {
    name: 'license.temporaryRegistrationNumber',
    getValue: (contractObject: any) =>
      contractObject?.contract?.license?.temporaryRegistrationNumber,
  },
];
