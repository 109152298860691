import { graphql, useStaticQuery } from 'gatsby';
import { useContext } from 'react';
import { PageContext } from '~/components/common/Layout';

export function useCraftCalculator() {
  const { site = '' } = useContext(PageContext);

  const data = useStaticQuery(graphql`
    query QueryCalculator {
      craft {
        entries(site: "*", type: Calculator) {
          fullUri
          site {
            handle
          }
        }
      }
    }
  `);

  const entry = data.craft.entries.find(
    (entry: any) => entry.site.handle === site,
  );

  return entry;
}
